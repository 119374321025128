import React from "react";
import {useState} from "react";
import ImageGrid from "./components/ImageGrid";
import Modal from "./components/Modal";
import UploadForm from "./components/UploadForm";
import { BrowserRouter, Routes, Route } from "react-router-dom";
// Add these 2 lines
import { BigDaddyProvider, BigDaddyComponent, BigDaddyRoute, BigDaddyCreatorRoute, BIGDADDY_PATH } from './BigDaddyNFT/BigDaddy-config';
import './BigDaddyNFT/BigDaddy-flow-config';

function App() {
  const [selectedImg, setSelectedImg] = useState(null);

  return (
    <BrowserRouter>
     <BigDaddyProvider siteId={process.env.REACT_APP_SITE_ID} 
     pathAfterAuth="/" 
     creatorPathAfterAuth="/admin">
        <Routes>
          <Route path={BIGDADDY_PATH} element={<BigDaddyComponent />} />
          <Route path="/" element={<BigDaddyRoute> <ImageGrid setSelectedImg={setSelectedImg} /></BigDaddyRoute>} />
          <Route path="/admin" element={<BigDaddyCreatorRoute><UploadForm /></BigDaddyCreatorRoute>} />
        </Routes>
        {selectedImg && <Modal selectedImg={selectedImg} setSelectedImg={setSelectedImg} />}
      </BigDaddyProvider>
  </BrowserRouter>   
  );
}

export default App;


