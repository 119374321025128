import React from 'react'
import * as fcl from "@onflow/fcl";
import { useBigDaddyContext } from '../Provider/BigDaddyContext.jsx';

export default function UserProfile() {
  const { 
    usdcBalance,
    flowBalance,
    user  } = useBigDaddyContext();
    


  return (

<div className="userProfile">
  <p>Address: </p>
  <p>{user.addr}</p>
<p>FLOW Balance: {parseFloat(flowBalance).toFixed(2)}</p>
<p>USDC Balance: {parseFloat(usdcBalance).toFixed(2)}</p>
</div>
  )
}