import {useState, useEffect} from "react";
import {projectFirestore} from "../firebase/config";

const useFirestore = () => {
  const [docs, setDocs] = useState([]);

  useEffect(() => {
    const unsub = projectFirestore
      .collection(process.env.REACT_APP_URL_PREFIX)
      .orderBy("createdAt", "desc")
      .onSnapshot((snap) => {
        let documents = [];
        snap.forEach((doc) => {
          documents.push({...doc.data(), id: doc.id});
        });
        setDocs(documents);
      });

    return () => unsub();
  }, []);

  return {docs};
};

export default useFirestore;
