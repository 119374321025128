export const BUY_2ND_HAND_NFT_TX = `import BigDaddyContract from 0xBdn
import FungibleToken from 0xFungibleToken
import FlowToken from 0xFlow
import FiatToken from 0xFiat

transaction(siteId: String, templateNumber: UInt32, price: UFix64) {
    let receiverReference: &BigDaddyContract.Collection{BigDaddyContract.Receiver}
    let template : BigDaddyContract.Template
    let sentVault: @FungibleToken.Vault
  
      prepare(signer: AuthAccount) {
        self.template = BigDaddyContract.getTemplate(siteID: siteId) ?? panic("No way to find template to buy")

        self.receiverReference = signer.borrow<&BigDaddyContract.Collection{BigDaddyContract.Receiver}>(from: BigDaddyContract.CollectionStoragePath) 
              ?? panic("Cannot borrow")
    
        if (self.template.token == "USDC"){
          // Get a reference to the signer's stored vault
          let vaultRef = signer.borrow<&FiatToken.Vault>(from: FiatToken.VaultStoragePath)
              ?? panic("Could not borrow reference to the owner's Vault!")
          self.sentVault <- vaultRef.withdraw(amount: price)
        }
        else {
          // Get a reference to the signer's stored vault
          let vaultRef = signer.borrow<&FlowToken.Vault>(from: /storage/flowTokenVault)
              ?? panic("Could not borrow reference to the owner's Vault!")
          self.sentVault <- vaultRef.withdraw(amount: price)
        }
      }
  
  
    execute {
      let nFT <- BigDaddyContract.purchaseNFTSecondHand(sentVault: <-self.sentVault, siteID: siteId, templateNumber: templateNumber)
      self.receiverReference.deposit(token: <- nFT)
    }
  }`;