import firebase from "firebase/compat/app";
import "firebase/compat/storage";
import "firebase/compat/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyA06orruYh3OQqGAt0E8EHpqcFpW1stGpM",
  authDomain: "bigdaddyphotoalbumtemplate.firebaseapp.com",
  projectId: "bigdaddyphotoalbumtemplate",
  storageBucket: "bigdaddyphotoalbumtemplate.appspot.com",
  messagingSenderId: "981279549726",
  appId: "1:981279549726:web:2dda962b30478ff85ac10f",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const projectStorage = firebase.storage();
const projectFirestore = firebase.firestore();
const timestamp = firebase.firestore.FieldValue.serverTimestamp;

export {projectFirestore, projectStorage, timestamp};
