import React from "react";
import useFirestore from "../hooks/useFirestore";
import {motion} from "framer-motion";
import { useBigDaddyContext } from '../BigDaddyNFT/Provider/BigDaddyContext.jsx';


function ImageGrid({setSelectedImg}) {
  const {docs} = useFirestore();
  
const { nftTemplate } = useBigDaddyContext();

  return (
    <><h1 className="album-title">{nftTemplate.name} Private Photo Album</h1>
    <div className="img-grid">

      {docs &&
        docs.map((doc) => (
          <motion.div
            layout
            whileHover={{ opacity: 1 }}
            className="img-wrap"
            key={doc.id}
            onClick={() => setSelectedImg(doc.url)}
          >
            <motion.img
              src={doc.url}
              alt="uploadded pic"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 1 }} />
          </motion.div>
        ))}
    </div></>
  );
}

export default ImageGrid;
