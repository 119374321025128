export const SELL_BIG_DADDY_NFT_TX = `import BigDaddyContract from 0xBdn
transaction(siteID: String, templateNumber: UInt32, price: UFix64) { 
    let seller: AuthAccount
    let sellerReference: &BigDaddyContract.Collection{BigDaddyContract.CollectionPublic}
  
    prepare(acct: AuthAccount) {
        self.seller = acct
        self.sellerReference = acct.borrow<&BigDaddyContract.Collection{BigDaddyContract.CollectionPublic}>(from: BigDaddyContract.CollectionStoragePath) 
          ?? panic("Cannot borrow seller reference")
    }
  
    execute {
      self.sellerReference.sellNFT(account: self.seller.address, siteID: siteID, templateNumber: templateNumber, price: price)
    }
  }
`;