import {useBigDaddyContext} from "../Provider/BigDaddyContext";
import UserProfile from "./BigDaddyAccountDetails";
import React, {useEffect, useState} from "react";
import * as fcl from "@onflow/fcl";


function BigDaddyHeaderComponent(){

    const {
        handleBuyNFT,
        nftTemplate,
        hasPersonnalAccess,
        redirectAfterAuth,
        nftimagePath,
        nftList,
        saleList,
        handleSellNFT,
        handleBuySecondHandNFT,
        disconnect,
        validateLoggedIn,
        isCreator,
        redirectCreatorAfterAuth,
        logoimagePath,
        isLoggedIn,
        isCollectionEnabled,
        handleActivateBigDaddyCollection
    } = useBigDaddyContext();

    const [isProfileOpen, setProfileOpen] = useState(false);

    useEffect(() => {
        fcl.currentUser.subscribe((currentUser) => {
            if (currentUser.cid) {

                validateLoggedIn(currentUser);

            } else {
                disconnect();
            }
        });

    }, [validateLoggedIn, disconnect]);

    const bigdaddyhandleLogIn = () => {
        fcl.logIn().then();
    };

    const handleLogOut = () => {
        fcl.unauthenticate();
        disconnect();
    };

    const toggleProfile = () => {
        setProfileOpen(!isProfileOpen);
    };

    return (
        <div className="activePage-header activePage-header-BuyerPage">
            <div className="logo-wrapper-BP">
                <img
                    src={logoimagePath}
                    width={"200px"}
                    height={"100px"}
                    alt={"logo"}/>
            </div>
            <div className="button-container">
                {hasPersonnalAccess && (
                    <button
                        onClick={redirectAfterAuth}
                        className="bigdaddy-button websitePanel-btn">
                        Go to Private Website
                    </button>
                )}
                {isCreator && (
                    <button
                        onClick={redirectCreatorAfterAuth}
                        className="bigdaddy-button websitePanel-btn">
                        Go to Creator Page
                    </button>
                )}
                {isLoggedIn ? (!isCollectionEnabled ? (
                    <button
                        onClick={handleActivateBigDaddyCollection}
                        className="bigdaddy-button websitePanel-btn">
                        Activate Collection
                    </button>
                ) :(
                    <>
                    <div>
                        <button
                            onClick={toggleProfile}
                            className="bigdaddy-button websitePanel-btn">
                            My account
                        </button>
                        {isProfileOpen && <UserProfile />}
                    </div>
                    <div>
                    <button
                        onClick={handleLogOut}
                        className="bigdaddy-button websitePanel-btn">
                        LogOut
                    </button>
                    </div>
                    </>
                )) : (
                    <button
                        onClick={bigdaddyhandleLogIn}
                        className="bigdaddy-button websitePanel-btn">
                        Login
                    </button>
                )}
            </div>
        </div>
    );
}
export default BigDaddyHeaderComponent;